const initialState = { data: [], isLoading: true };

const setEdgeNames = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EDGE_NAMES":
      return (state = action.payload);
    default:
      return state;
  }
};

export default setEdgeNames;
