import React, { useState, useLayoutEffect, useEffect, useMemo } from "react";
import Heading from "../SectionHeading/Heading";
import "./Details.css";
import { routes } from "../../constants/RoutesConstants";
import { IoIosSettings } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmUpgradeDialouge,
  setEdgeDetails,
  showEdgeSettings,
} from "../../reduxStore/actions";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { RiSettings5Fill } from "react-icons/ri";
import { IoSyncSharp } from "react-icons/io5";
import NoDataComponent from "../NoDataComponent/NoDataComponent";
import Axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import { Button, Popover, Table, Modal, Form, Input, message } from "antd";
import { Tooltip, WithTooltip } from "@abb/abb-common-ux-react";
import { CreateGuid, generateTime } from "../../constants/commonFunction";
import { setPageNotifications } from "../../reduxStore/actions";
import { GeneralConstants } from "../../constants/GeneralConstants";
import ReactMarkdown from "react-markdown";
import WarningIcons from "../WarningIcons/WarningIcons";

function Details({ isSetting, props }) {
  const dispatch = useDispatch();
  // const [data, setData] = useState(detailsData);
  let oldNotifications = useSelector((state) => state.setPageNotifications);
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  let detailsData = useSelector((state) => state.setEdgeDetails);
  let data = useSelector((state) => state.setEdgeDetails.data);
  let isLoading = useSelector((state) => state.setEdgeDetails.isLoading);
  const [pathname, setPathname] = useState("");
  let isEdgeDeploying = useSelector((state) => state.setIsEdgeDeploying);

  let isEdgeUpdateAvailable = useSelector(
    (state) => state.isEdgeUpdateAvailable
  );
  const appState = useSelector(
    (state) => state.setApplicationDetails.applicationDetails.state
  );
  const appStateError = useSelector(
    (state) => state.setApplicationDetails.applicationDetails.error
  );
  let [count, setCount] = useState(0);
  const clickedApply = useSelector((state) => state.confirmUpgradeDialouge);
  const [openNotification, setOpenNotification] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const platformType = useSelector(
    (state) => state.setEdgeDetails?.data?.[4]?.detail
  );
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 3000); // 5000 milliseconds = 5 seconds

    return () => clearTimeout(timer);
  }, []);
  const [showLicenseModal, setShowLicenseModal] = useState(false);
  const licenceContent = useMemo(() => {
    // console.log("The License Content", props?.licenseText);
    return props?.isLoading
      ? "Loading..."
      : atob(props?.licenseText || "") || "Not configured.";
  }, [props]);

  const progresConstant = GeneralConstants.progresConstant;

  const updateEdge = async () => {
    dispatch(confirmUpgradeDialouge({ payload: true }));
    setUpdateModalVisible(false);
  };

  const handleUpdateOk = () => {
    setUpdateModalVisible(true);
  };

  const handleUpdateCancel = () => {
    setUpdateModalVisible(false);
  };

  useLayoutEffect(() => {
    setPathname(window.location.pathname);
  }, []);

  const [isAppUpdateAvailable, setIsAppUpdateAvailable] = useState(false);
  const [AppupdateModalVisible, setAppUpdateModalVisible] = useState(false);
  const [applicationTypeIdToUpdate, setApplicationTypeIdToUpdate] =
    useState(null);
  const [updateload, setUpdateLoad] = useState(false);
  const [typeId, setTypeId] = useState("");
  const [settingData_typeId, setsettingData_typeId] = useState({});
  useEffect(() => {
    if (window.location.pathname.includes("appDetails")) {
      const isAppInstalled = queryParam.get("isAppInstalled");
      const settingData = sessionStorage.getItem("settingData");
      setsettingData_typeId(JSON.parse(settingData));
      const typee = JSON.parse(settingData)?.type;
      setTypeId(settingData_typeId?.type);
      if (isAppInstalled == "true") {
        let checkAppUpdate = apiEndPoints.CHECK_UPDATE_APPLICATION.replace(
          "*edgeId*",
          edgeId
        );
        checkAppUpdate = checkAppUpdate.replace("*typeId*", typee);
        const fetchUpdateStatus = async () => {
          try {
            const response = await Axios.get(checkAppUpdate);

            setIsAppUpdateAvailable(
              response?.data?.[0]?.applicationUpdateAvailable
            );
          } catch (error) {
            console.error("Error fetching update status:", error);
          }
        };
        fetchUpdateStatus();
      }
    }
  }, []);

  const showUpdateModal = (type) => {
    setApplicationTypeIdToUpdate(type);
    setAppUpdateModalVisible(true);
  };
  const handleAppUpdateCancel = () => {
    setAppUpdateModalVisible(false);
  };

  const handleAppUpdateOk = () => {
    handleUpdateApplication(applicationTypeIdToUpdate);
  };

  const AddEdgeSettingsNotification = async (notiText, notiId, data) => {
    let previosData = [];
    if (oldNotifications.newData.length > 0) {
      previosData = [...oldNotifications.newData];
    }

    previosData.push({
      notiId: `${notiId}`,
      text: `${notiText}`,
      time: data.time,
      data,
    });

    dispatch(
      setPageNotifications({
        payload: {
          isNew: true,
          data: oldNotifications.data,
          newData: previosData,
          // ...oldNotifications.newData,
        },
      })
    );
  };

  let AppUpdate = apiEndPoints.UPDATE_APPLICATION.replace("*edgeId*", edgeId);
  let notiMsg = "";
  const handleUpdateApplication = async (type) => {
    setUpdateLoad(true);
    AppUpdate = AppUpdate.replace("*type*", type);
    try {
      const response = await Axios.post(AppUpdate);
      if (response.status === 200) {
        setUpdateLoad(false);

        AddEdgeSettingsNotification(
          `Application updated successfully`,
          `AppRelatedUpdate_${CreateGuid()}`,

          {
            edgeId: edgeId,
            isEdge: false,
            modalKey: `AppRelatedUpdate_${CreateGuid()}`,
            setting_type: type,
            settingSectionName: data?.[0]?.detail,
            operation_type: "configure",
            edgeName: localStorage.getItem("edgeName"),
            appName: data?.[0]?.detail,
            successMessage: `Application updated successfully`,
            time: generateTime(),
            isComplete: false,
            limit: 3,
            currStateValue: 0,
            icon: "good",
          }
        );
        setIsAppUpdateAvailable(false);
        setAppUpdateModalVisible(false);
        refreshCheckAvailability();
        refreshgetDetails();
      } else {
        setUpdateLoad(false);
        notiMsg = "Application update failed, please try again";
        message.error(notiMsg);
        AddEdgeSettingsNotification(
          "Application update failed, please try again",
          `AppRelatedUpdate_${CreateGuid()}`,

          {
            edgeId: edgeId,
            isEdge: false,
            modalKey: `AppRelatedUpdate_${CreateGuid()}`,
            edgeName: localStorage.getItem("edgeName"),
            appName: data?.[0]?.detail,
            successMessage: "Application update failed, please try again",
            time: generateTime(),
            isComplete: true,
            limit: 3,
            currStateValue: 0,
            icon: "failed",
          }
        );
        setAppUpdateModalVisible(false);
      }
    } catch (error) {
      console.error(error);
      setUpdateLoad(false);
      notiMsg = "Application update initialized failed, please try again";
      AddEdgeSettingsNotification(
        `${notiMsg}`,
        `AppRelatedUpdate_${CreateGuid()}`,

        {
          edgeId: edgeId,
          isEdge: false,
          modalKey: `AppRelatedUpdate_${CreateGuid()}`,
          edgeName: localStorage.getItem("edgeName"),
          appName: data?.[0]?.detail,
          successMessage: `${notiMsg}`,
          time: generateTime(),
          isComplete: true,
          limit: 3,
          currStateValue: 0,
          icon: "failed",
        }
      );
      message.error(notiMsg);

      setAppUpdateModalVisible(false);
    }
  };

  const refreshCheckAvailability = async () => {
    let toGetAppUpdate = apiEndPoints.CHECK_UPDATE_APPLICATION.replace(
      "*edgeId*",
      edgeId
    );
    toGetAppUpdate = toGetAppUpdate.replace(
      "*typeId*",
      applicationTypeIdToUpdate
    );

    const response = await Axios.get(toGetAppUpdate);
    setIsAppUpdateAvailable(response?.data?.[0]?.applicationUpdateAvailable);
    return response.data;
  };

  const refreshgetDetails = async () => {
    let toGetAppDetails =
      apiEndPoints.FETCH_INSTALLED_APPLICATION_DETAILS.replace(
        "*edgeId*",
        edgeId
      );
    toGetAppDetails = toGetAppDetails.replace(
      "*typeId*",
      applicationTypeIdToUpdate
    );

    const response = await Axios.get(toGetAppDetails);
    updateTheData(response.data);
    return response.data;
  };

  const updateTheData = (data) => {
    dispatch(
      setEdgeDetails({
        payload: {
          data: [
            {
              heading: "Application Name",
              detail: data?.name,
            },
            {
              heading: "Description",
              detail: data?.description,
            },
          ],
          isLoading: false,
        },
      })
    );
  };

  const renderStatus = (status) => {
    switch (status) {
      case "connectondemand":
        return "Connect on demand";
      case "Standalone":
        return "Standalone";
      case "unknown":
        return "-";
      default:
        return status;
    }
  };

  const updateThisEdge = () => {
    // Clear correlationId from localStorage

    function CreateGuid() {
      function _p8(s) {
        var p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
      }
      return _p8() + _p8(true) + _p8(true) + _p8();
    }
    if (platformType != "unknown") {
      const guid = CreateGuid();
      localStorage.setItem("correlationId", guid);
    }

    AddEdgeSettingsNotification(
      `Edge updated successfully`,
      `edgeSettingUpdate_${CreateGuid()}`,

      {
        isUpdate: true,
        edgeId: edgeId,
        isEdge: true,
        modalKey: `edgeSettingUpdate_${CreateGuid()}`,
        edgeName: localStorage.getItem("edgeName"),
        appName: "",
        successMessage: `Edge updated successfully`,
        time: generateTime(),
        isComplete: false,
        limit: 5,
        currStateValue: 0,
        icon: "good",
      }
    );
  };

  return (
    <div className="App detailsApp">
      <span
        className="Heading_container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          columnGap: "10px",
          width: "98%",
          borderBottom: "1px solid #f0f0f0",
        }}
      >
        {pathname.includes("appDetails") ? (
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <Heading text={data?.[0]?.detail} />
            <span style={{ marginLeft: "-10px", marginTop: "3px" }}>
              <WithTooltip>
                {appState == 4 || appState == 1 ? (
                  <SpinnerLoader />
                ) : (
                  <WarningIcons
                    type={
                      appState == 2
                        ? "good"
                        : appState == 3 || appState == 5
                        ? "Error"
                        : ""
                    }
                  />
                )}
                <Tooltip>
                  <p>
                    {appState == 1
                      ? "Installing"
                      : appState == 2
                      ? "Installed"
                      : appState == 3
                      ? "Installation Failed"
                      : appState == 4
                      ? "Uninstalling"
                      : appState == 5
                      ? "Error"
                      : ""}
                  </p>
                  <p>{appStateError}</p>
                </Tooltip>
              </WithTooltip>
            </span>
          </div>
        ) : (
          <Heading text="Details" />
        )}
        {isLoading && <SpinnerLoader style={{ marginRight: "auto" }} />}

        {pathname.includes("appDetails") && isAppUpdateAvailable && (
          <button
            style={{
              cursor: "pointer",
              marginLeft: "auto",
              fontSize: "22px",
              marginRight: "10px",
              marginBottom: "-7px",
              background: "unset",
              border: "none",
            }}
            // onClick={() => showUpdateModal()}
          >
            <WithTooltip>
              <IoSyncSharp
                style={{
                  color: "#1f1f1f",
                  cursor: "pointer",
                }}
                onClick={() => showUpdateModal(settingData_typeId.type)}
                // onClick={() => console.log(settingData_typeId.type)}
              />
              <Tooltip>
                <p>Update Available</p>
                <p>Latest Version {`${settingData_typeId.version}`}</p>
              </Tooltip>
            </WithTooltip>
          </button>
        )}
      </span>
      {/* <hr style={{marginTop: '30px' }}/> */}
      <div className="edgeDetails">
        <div className="leftsection" style={{ width: "100%" }}>
          {data.length > 0 &&
            !isLoading &&
            data?.map((d, i) => (
              <>
                {pathname.includes("appDetails") ? (
                  ""
                ) : (
                  <span
                    className="heading"
                    style={{
                      display:
                        d.heading == "Connectivity status" ? "none" : "block",
                    }}
                  >
                    {d.link ? (
                      <>
                        <a className="Hyper_link" href={d.link}>
                          {d.heading}
                        </a>
                      </>
                    ) : (
                      <>
                        {d.heading == "Connectivity status"
                          ? "Connectivity Mode"
                          : d.heading}
                      </>
                    )}
                  </span>
                )}
                {pathname.includes("edgedetails") && (
                  <div
                    style={{
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <span
                      className="detail"
                      style={{
                        display:
                          d.heading == "Connectivity status" ? "none" : "block",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {renderStatus(d.detail)}
                    </span>
                  </div>
                )}

                {pathname.includes("appDetails") && i > 0 && (
                  <span className="detail" style={{ marginTop: "10px" }}>
                    {d.detail}
                  </span>
                )}
              </>
            ))}

          {data.length == 0 && !isLoading && <NoDataComponent />}
          {pathname.includes("appDetails") && showText && (
            <span
              style={{
                color: "#0052ff",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                fontWeight: "500",
                border: "0px",
                fontFamily: "ABBVoice",
                fontSize: "15px",
                boxShadow: "none",
                marginTop: "20px",
              }}
              onClick={() => {
                setShowLicenseModal(true);
              }}
            >
              Open Source License
            </span>
          )}
        </div>
      </div>
      {/* <Modal
        title="Update Edge"
        open={updateModalVisible}
        onCancel={handleUpdateCancel}
      >
        <p style={{ fontSize: "medium" }}>
          Please Confirm you want to Update the Edge.
        </p>
        <Button
          style={{
            marginLeft: "60%",
            background: "#1f1f1f",
            alignItems: "center",
            borderRadius: "30px",
            cursor: "pointer",
            color: "white",
            fontFamily: "ABBVoice Regular",
          }}
          type="primary"
          onClick={updateEdge}
        >
          Update
        </Button>
        <Button
          style={{
            borderRadius: "30px",
            marginTop: "20px",
            marginLeft: "20px",
            fontWeight: "500",
            fontFamily: "ABBVoice Regular",
          }}
          onClick={handleUpdateCancel}
        >
          Cancel
        </Button>
      </Modal> */}
      <Modal
        title="Update Application?"
        open={AppupdateModalVisible}
        // onOk={handleDeleteOk}
        onCancel={handleAppUpdateCancel}
      >
        <p style={{ fontSize: "medium" }}>
          Please confirm to update the application.
        </p>
        <Form onFinish={handleUpdateApplication} layout="vertical">
          <Button
            style={{
              marginLeft: "55%",
              background: "#1f1f1f",
              // display: "flex",
              alignItems: "center",
              borderRadius: "30px",
              // padding: "2px 10px",
              cursor: "pointer",
              color: "white",
              fontFamily: "ABBVoice Regular",
            }}
            type="primary"
            onClick={handleAppUpdateOk}
            loading={updateload}
          >
            Update
          </Button>
          <Button
            style={{
              borderRadius: "30px",
              marginTop: "20px",
              marginLeft: "20px",
              fontWeight: "500",
              fontFamily: "ABBVoice Regular",
            }}
            onClick={handleAppUpdateCancel}
          >
            Cancel
          </Button>
        </Form>
      </Modal>
      <Modal
        centered
        open={showLicenseModal}
        onOk={() => setShowLicenseModal(false)}
        onCancel={() => setShowLicenseModal(false)}
        width="80%"
      >
        <div
          className="Heading_container overFlowText"
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
            width: "22%",
            fontFamily: "ABBVoice",
            // marginTop: "-43px"
          }}
        >
          <Heading additionalClass="overFlowText" text="Open Source License" />
        </div>

        <hr style={{ marginTop: "0px" }} />
        <>
          {/* {view ? (
            <span className="heading_2">
              <NoDataComponent />
            </span>
          ) : ( */}
          <ReactMarkdown children={licenceContent} />
          {/* )} */}
        </>
      </Modal>
    </div>
  );
}

export default Details;
