import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DummyAreaChart from "../../components/DummyAreaChart";
import {
  Switch,
  InputNumber,
  Button,
  Form,
  Input,
  message,
  Tooltip,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { General_color } from "../../constants/GeneralConstants";
import axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import {
  setApiReloader,
  setResourceLimitGraphData,
  setPageNotifications,
} from "../../reduxStore/actions";
import { RiEdit2Fill } from "react-icons/ri";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import { CreateGuid, generateTime } from "../../constants/commonFunction";
import { getTimeSpan } from "../../constants/commonFunction";

function ResourceMonitorGraphs() {
  const [sudoState, setSudoState] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  let fetched_pod_graphData = useSelector(
    (state) => state.setResourceMonitorGraph.data
  );
  const matricsData = useSelector((state) => state.setData.data);
  let ApiLoaderVal = useSelector((state) => state.setApiReloader);

  const [showLimits, setShowLimits] = useState(false);
  let [pod_graphData, setPod_graphData] = useState({});
  let [moduleData, setModuleData] = useState({});
  const [confirmLoad, setConfirmload] = useState(false);
  const [originalModuleData, setOriginalModuleData] = useState({});
  const [firstTimestamp, setFirstTimestamp] = useState(null);

  const handleFirstTimestampChange = (timestamp) => {
    setFirstTimestamp(timestamp);
  };

  let oldNotifications = useSelector((state) => state.setPageNotifications);
  const AddEdgeSettingsNotification = async (notiText, notiId, data) => {
    let previosData = [];
    if (oldNotifications.newData.length > 0) {
      previosData = [...oldNotifications.newData];
    }

    previosData.push({
      notiId: `${notiId}`,
      text: `${notiText}`,
      time: data.time,
      data,
    });

    dispatch(
      setPageNotifications({
        payload: {
          isNew: true,
          data: oldNotifications.data,
          newData: previosData,
          // ...oldNotifications.newData,
        },
      })
    );
  };
  useEffect(() => {
    setShowLimits(false);

    let copy_podGraphData = JSON.stringify(fetched_pod_graphData);
    setPod_graphData(JSON.parse(copy_podGraphData));
    if (fetched_pod_graphData?.children) {
      // Handle applications
      let initialModuleData = {};
      fetched_pod_graphData.children.forEach((child) => {
        child.containers.map((container) => {
          initialModuleData[container.containerName] = {
            cpulimit: container?.cpulimit !== "null" ? container?.cpulimit : "",
            memorylimit:
              container?.memorylimit !== "null" ? container?.memorylimit : "",
          };
        });
      });
      setModuleData(initialModuleData);
      setOriginalModuleData(initialModuleData);
    } else {
      // Handle Pods
      let initialModuleData = {
        [fetched_pod_graphData.name]: {
          cpulimit:
            fetched_pod_graphData.cpulimit !== "null"
              ? fetched_pod_graphData.cpulimit
              : "",
          memorylimit:
            fetched_pod_graphData.memorylimit !== "null"
              ? fetched_pod_graphData.memorylimit
              : "",
        },
      };
      setModuleData(initialModuleData);
      setOriginalModuleData(initialModuleData);
    }
  }, [fetched_pod_graphData]);

  useEffect(() => {}, [sudoState]);

  const graphType = useSelector((state) =>
    state?.setResourceMonitorGraph?.graphType
      ? state.setResourceMonitorGraph.graphType
      : ""
  );
  const dispatch = useDispatch();

  const onReset = () => {
    if (showLimits && isChanged) {
      setModuleData(originalModuleData);
      setIsChanged(false);
    }
  };

  const handleToggle = () => {
    setShowLimits(!showLimits);
    if (!showLimits) {
      setModuleData(originalModuleData);
      setIsChanged(false);
    }
  };

  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");
  const tenant = localStorage.getItem("tenant");
  const handleConfirm = () => {
    setConfirmload(true);
    if (graphType !== "pod") {
      // Applications logic
      let updatedModules = pod_graphData.children
        .map((child) => {
          let updatedContainers = child.containers
            .map((container) => {
              const prevCPU =
                originalModuleData[container.containerName]?.cpulimit;
              const prevMemory =
                originalModuleData[container.containerName]?.memorylimit;
              const newCPU = moduleData[container.containerName]?.cpulimit;
              const newMemory =
                moduleData[container.containerName]?.memorylimit;

              if (prevCPU !== newCPU || prevMemory !== newMemory) {
                return {
                  name: container.containerName,
                  resources: {
                    limits: {
                      cpu: newCPU,
                      memory: newMemory,
                    },
                  },
                };
              }
              return null;
            })
            .filter(Boolean);

          if (updatedContainers.length > 0) {
            return {
              moduleId: child.name,
              Containers: updatedContainers,
            };
          }
          return null;
        })
        .filter(Boolean);

      let data = JSON.stringify({
        edgeId: edgeId,
        applicationObjectId: fetched_pod_graphData?.applicationObjectId,
        modules: updatedModules,
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: apiEndPoints.UPDATE_APP_RESOURCES,
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setConfirmload(false);
          let notiMsg = `Resource Limits applied successfully for ${pod_graphData?.name}.`;
          AddEdgeSettingsNotification(
            `${notiMsg}`,
            `EdgeRelatedUpdate_${CreateGuid()}`,
            {
              edgeId: edgeId,
              isEdge: true,
              modalKey: `EdgeRelatedUpdate_${CreateGuid()}`,
              edgeName: localStorage.getItem("edgeName"),
              appName: "",
              successMessage: `${notiMsg}`,
              time: generateTime(),
              isComplete: true,
              limit: 3,
              currStateValue: 0,
              icon: "good",
            }
          );
          let prevApiLoaderVal = ApiLoaderVal + 1;
          dispatch(setApiReloader({ payload: prevApiLoaderVal }));
          setOriginalModuleData(moduleData);
          setIsChanged(false);
        })
        .catch((error) => {
          console.log(error);
          setConfirmload(false);
          let notiMsg = `Applying Resource Limits failed for ${pod_graphData?.name}, Please try again.`;
          AddEdgeSettingsNotification(
            `${notiMsg}`,
            `EdgeRelatedUpdate_${CreateGuid()}`,

            {
              edgeId: edgeId,
              isEdge: true,
              modalKey: `EdgeRelatedUpdate_${CreateGuid()}`,
              edgeName: localStorage.getItem("edgeName"),
              appName: "",
              successMessage: `${notiMsg}`,
              time: generateTime(),
              isComplete: true,
              limit: 3,
              currStateValue: 0,
              icon: "failed",
            }
          );
        });
    } else {
      // Platform services logic
      let payload = {
        edgeId: edgeId,
        applicationObjectId: fetched_pod_graphData?.applicationObjectId,
        module: {
          moduleId: fetched_pod_graphData?.name,
          resources: {
            limits: {
              cpu: moduleData[fetched_pod_graphData?.name].cpulimit,
              memory: moduleData[fetched_pod_graphData?.name].memorylimit,
            },
          },
        },
      };

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: apiEndPoints.UPDATE_PLATFORM_RESOURCES,

        data: JSON.stringify(payload),
      };
      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setConfirmload(false);
          let notiMsg = `Resource Limits applied successfully for ${pod_graphData?.name}.`;
          AddEdgeSettingsNotification(
            `${notiMsg}`,
            `EdgeRelatedUpdate_${CreateGuid()}`,
            {
              edgeId: edgeId,
              isEdge: true,
              modalKey: `EdgeRelatedUpdate_${CreateGuid()}`,
              edgeName: localStorage.getItem("edgeName"),
              appName: "",
              successMessage: `${notiMsg}`,
              time: generateTime(),
              isComplete: true,
              limit: 3,
              currStateValue: 0,
              icon: "good",
            }
          );
          let prevApiLoaderVal = ApiLoaderVal + 1;
          dispatch(setApiReloader({ payload: prevApiLoaderVal }));
          setOriginalModuleData(moduleData);
          setIsChanged(false);
        })
        .catch((error) => {
          console.log(error);
          setConfirmload(false);
          let notiMsg = `Applying Resource Limits failed for ${pod_graphData?.name}, Please try again.`;
          AddEdgeSettingsNotification(
            `${notiMsg}`,
            `EdgeRelatedUpdate_${CreateGuid()}`,

            {
              edgeId: edgeId,
              isEdge: true,
              modalKey: `EdgeRelatedUpdate_${CreateGuid()}`,
              edgeName: localStorage.getItem("edgeName"),
              appName: "",
              successMessage: `${notiMsg}`,
              time: generateTime(),
              isComplete: true,
              limit: 3,
              currStateValue: 0,
              icon: "failed",
            }
          );
        });
    }
  };
  const checkPositiveValues = (data) => {
    for (let key in data) {
      const { cpulimit, memorylimit } = data[key];
      if ((cpulimit && cpulimit > 0) || (memorylimit && memorylimit > 0)) {
        return true;
      }
    }
    return false;
  };

  const handleInputChange = (name, type, value) => {
    setModuleData((prevData) => {
      const newData = {
        ...prevData,
        [name]: {
          ...prevData[name],
          [type]: value,
        },
      };
      setIsChanged(
        showLimits &&
          JSON.stringify(newData) !== JSON.stringify(originalModuleData) &&
          checkPositiveValues(newData)
      );
      return newData;
    });
  };

  const graphContainerStyle = {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    paddingBottom: "25px",
    marginTop: "25px",
    alignItems: "center",
    borderBottom: "1px solid #e2e2e2",
  };

  const leftPanelStyle = {
    width: "60%",
    position: "relative",
  };

  const graphTitleStyle = {
    fontFamily: "ABBVoice-bold",
    fontSize: "18px",
    marginBottom: "20px",
  };

  const hrStyle = {
    height: "100px",
    position: "absolute",
    top: "20%",
    width: "1px",
    right: "0",
  };

  const rightPanelStyle = {
    width: "40%",
  };

  const containerDivStyle = {
    marginBottom: "15px",
  };

  const containerNameStyle = {
    fontFamily: "ABBVoice-bold",
    fontSize: "16px",
    marginBottom: "10px",
    display: "block",
  };

  const formStyle = {
    display: "flex",
    flexDirection: "row",
    gap: "30px",
    alignItems: "center",
    justifyContent: "center",
  };

  const labelStyle = {
    fontFamily: "ABBVoice",
    fontSize: "15px",
    marginRight: "10px",
  };

  const unitStyle = {
    fontFamily: "ABBVoice",
    fontSize: "12px",
    marginLeft: "5px",
  };
  function bytesToMB(bytes) {
    if (bytes === 0) return "n/a";
    const MB = bytes / (1024 * 1024);
    return MB.toFixed(2);
  }

  const renderGraphDiv = (Metric_data, index) => {
    return (
      <div key={index} className="graph-container" style={graphContainerStyle}>
        <div className="left-panel" style={leftPanelStyle}>
          <span style={graphTitleStyle}>
            {graphType !== "pod" && Metric_data.name}
          </span>
          <div style={{ color: "#989898" }}>{getTimeSpan(firstTimestamp)}</div>
          <DummyAreaChart
            name={Metric_data.name}
            onFirstTimestampChange={handleFirstTimestampChange}
          />
          <hr style={hrStyle} />
        </div>
        <div className="right-panel" style={rightPanelStyle}>
          {graphType === "pod" ? (
            <div style={containerDivStyle}>
              <Form style={formStyle} disabled>
                <FormItem>
                  <span style={labelStyle}>CPU</span>
                  <InputNumber
                    type="number"
                    disabled={!showLimits || confirmLoad}
                    value={moduleData[Metric_data.name]?.cpulimit || ""}
                    min={1}
                    max={`${matricsData?.[0]?.data?.[0].v * 1000}`}
                    onChange={(value) =>
                      handleInputChange(Metric_data.name, "cpulimit", value)
                    }
                  />
                  <span style={unitStyle}>milliCores</span>
                </FormItem>
                <FormItem>
                  <span style={labelStyle}>RAM</span>
                  <InputNumber
                    type="number"
                    disabled={!showLimits || confirmLoad}
                    value={moduleData[Metric_data.name]?.memorylimit || ""}
                    min={12}
                    max={`${bytesToMB(matricsData?.[2]?.data?.[0].v)}`}
                    onChange={(value) =>
                      handleInputChange(Metric_data.name, "memorylimit", value)
                    }
                  />
                  <span style={unitStyle}>MB</span>
                </FormItem>
              </Form>
            </div>
          ) : (
            Metric_data?.containers?.map((container, containerIndex) => (
              <div key={containerIndex} style={containerDivStyle}>
                <span style={containerNameStyle}>
                  {container.containerName}
                </span>
                <Form style={formStyle} disabled>
                  <FormItem>
                    <span style={labelStyle}>CPU</span>
                    <InputNumber
                      type="number"
                      disabled={!showLimits || confirmLoad}
                      value={moduleData[container.containerName]?.cpulimit}
                      min={1}
                      max={`${matricsData?.[0]?.data?.[0].v * 1000}`}
                      onChange={(value) =>
                        handleInputChange(
                          container.containerName,
                          "cpulimit",
                          value
                        )
                      }
                    />
                    <span style={unitStyle}>milliCores</span>
                  </FormItem>
                  <FormItem>
                    <span style={labelStyle}>RAM</span>
                    <InputNumber
                      type="number"
                      disabled={!showLimits || confirmLoad}
                      value={
                        moduleData[container.containerName]?.memorylimit || ""
                      }
                      min={12}
                      max={`${bytesToMB(matricsData?.[2]?.data?.[0].v)}`}
                      onChange={(value) =>
                        handleInputChange(
                          container.containerName,
                          "memorylimit",
                          value
                        )
                      }
                    />
                    <span style={unitStyle}>MB</span>
                  </FormItem>
                </Form>
              </div>
            ))
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="App">
      <div className="resource-monitor" style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "95%",
          }}
        >
          <b style={{ fontFamily: "ABBVoice", fontSize: "18px" }}>Resources</b>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                fontFamily: "ABBVoice",
                fontSize: "15px",
                marginRight: "8px",
              }}
            >
              Set Limits
            </span>
            <RiEdit2Fill
              onClick={() => {
                if (
                  tenant?.toLowerCase() !== "chiron".toLowerCase() ||
                  graphType === "pod" ||
                  confirmLoad
                ) {
                  return;
                }
                handleToggle();
              }}
              style={{
                cursor:
                  tenant?.toLowerCase() !== "chiron".toLowerCase() ||
                  graphType === "pod" ||
                  confirmLoad
                    ? "not-allowed"
                    : "pointer",
                color:
                  tenant?.toLowerCase() !== "chiron".toLowerCase() ||
                  graphType === "pod" ||
                  confirmLoad
                    ? "grey"
                    : "black",
                fontSize: "20px",
              }}
            />
          </div>
        </div>
        <div style={{ marginTop: "30px", marginBottom: "20px" }}>
          <span style={{ fontFamily: "ABBVoice-bold", fontSize: "18px" }}>
            {pod_graphData?.name}
          </span>
        </div>
        <div style={{ overflow: "auto", height: "45vh" }}>
          {graphType === "pod"
            ? renderGraphDiv(pod_graphData, 0)
            : pod_graphData?.children?.map((item, index) =>
                renderGraphDiv(item, index)
              )}
        </div>
        <div
          className="button-container"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "20px",
            marginTop: "auto",
            right: "0",
            zIndex: 1000,
            padding: "20px",
            position: "sticky",
            bottom: "0",
            backgroundColor: "#fff",
            zIndex: 1000,
          }}
        >
          <Button
            type="primary"
            className="text_1"
            style={{
              background:
                !showLimits || !isChanged ? General_color.disable : "#1f1f1f",
              display: "flex",
              alignItems: "center",
              borderRadius: "31px",
              padding: "5px 15px",
              cursor: "pointer",
              fontWeight: "500",
              border: "0px",
              color: "white",
              marginLeft: "auto",
              fontFamily: "ABBVoice",
            }}
            onClick={handleConfirm}
            loading={confirmLoad}
            disabled={!showLimits || !isChanged || confirmLoad}
          >
            Confirm
          </Button>
          <Button
            className="text_1"
            style={{
              fontFamily: "ABBVoice",
              color: "#fff",
              fontWeight: "500",
              background:
                !showLimits || !isChanged || confirmLoad
                  ? General_color.disable
                  : "#1f1f1f",
              padding: "0px 15px",
              borderRadius: "100px",
            }}
            onClick={onReset}
            disabled={!showLimits || !isChanged || confirmLoad}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ResourceMonitorGraphs;
