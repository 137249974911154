/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect, useDebugValue, useRef } from "react";
import "./Widget.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Select, Tooltip, message } from "antd";
import { IoIosAdd } from "react-icons/io";
import Axios from "axios";
import { apiEndPoints } from "../../constants/ApiEndPoints";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import {
  isUnsavedEdgeSettings,
  setPageNotifications,
  setRouterEdgeSettings,
  setRoutesModels,
  setTempRoutes,
} from "../../reduxStore/actions";
import { CreateGuid, generateTime } from "../../constants/commonFunction";
import {
  GeneralConstants,
  General_color,
} from "../../constants/GeneralConstants";
import axios from "axios";

function Widget() {
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [searchedValue, setSearchedValue] = useState("");
  const [defaultRouteValue, setDefaultRouteValue] = useState("");
  const [toCheckData, setToCheckData] = useState(null);
  let data = useSelector((state) => state.setRouterEdgeSettings);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (data?.properties?.[0]?.value == null) {
      data.properties[0].value = {};
    }
    setDefaultRouteValue(data?.properties?.[0]?.value?.defaultRoute?.value);
  }, [data]);
  useEffect(() => {
    const fetchRouterModules = async () => {
      let fetchRouterModulesUrl = apiEndPoints.FETCH_ROUTER_MODULES.replace(
        "*edgeId*",
        edgeId
      );
      let routerConfig = {
        method: "get",
        maxBodyLength: Infinity,
        url: fetchRouterModulesUrl,
      };

      try {
        const response = await axios.request(routerConfig);
        dispatch(setRoutesModels({ payload: response.data }));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchRouterModules();
  }, []);

  const dispatch = useDispatch();
  const queryParam = new URLSearchParams(window.location.search);
  const edgeId = queryParam.get("edgeId");

  // const [newData, setNewData] = useState([])
  const [sudoState, setSudoState] = useState(0);

  const routesModel = useSelector((state) => state.setRoutesModels);
  const [routeModules, setRoutesModules] = useState([
    { label: "select", value: "select" },
  ]);

  const [modelsRoute, setModelsRoute] = useState([
    { label: "select", value: "select" },
  ]);
  const [typeModules, setTypeModules] = useState([
    { label: "select", value: "select" },
  ]);

  let tempRoutes = useSelector((state) => state.setTempRoutes);

  useEffect(() => {
    if (!loading && routesModel && routesModel.properties) {
      let refactoredRouteModel = Object.keys(
        routesModel.properties.modelIds
      ).map((item) => ({
        label: routesModel.properties.modelIds[item],
        value: item,
      }));
      let refactoredRouteType = Object.keys(routesModel.properties.types).map(
        (item) => ({
          label: item === "timeseries" ? "variable data" : item,
          value: item,
        })
      );
      let refactoredRoutingroute = Object.keys(routesModel.properties.routes)
        .filter((item) => item.toLowerCase() !== "cloud")
        .map((item) => ({
          label: item,
          value: item,
        }));

      refactoredRouteModel.unshift({ label: "select", value: "select" });
      refactoredRouteType.unshift({ label: "select", value: "select" });
      refactoredRoutingroute.unshift({ label: "select", value: "select" });

      setRoutesModules(refactoredRouteModel);
      setModelsRoute(refactoredRoutingroute);
      setTypeModules(refactoredRouteType);
    }
  }, [loading, routesModel]);
  let detailsData = useSelector((state) => state.setEdgeDetails);
  const [editingRouteKey, setEditingRouteKey] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [ischanged, setIsChanged] = useState(false);
  const [showAddRoutesPannel, setShowAddRoutesPannel] = useState(false);
  const [newRouteType, setNewRouteType] = useState("");
  const [newRouteModel, setNewRouteModel] = useState("");
  const { Option } = Select;
  const [newRoute, setNewRoute] = useState("");
  const [newInitialRoutes, setNewInitialRoutes] = useState({});
  const [addroutesEnable, setaddroutesEnable] = useState(false);
  const [typeOfVariable, setTypeOfVariable] = useState("");
  const resetRoutes = () => {
    setNewRouteType("select");
    setNewRouteModel("select");
    setNewRoute("select");
    setEditingRouteKey(null);
  };

  useEffect(() => {
    setNewInitialRoutes(tempRoutes);
    if (tempRoutes?.properties?.[0]?.value?.routes == undefined) return;
    let checkKeys = Object.keys(tempRoutes?.properties?.[0]?.value?.routes);
    if (checkKeys.length > 0) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [tempRoutes]);

  const checkIsRoutePresent = (newRoute, newRouteType, newRouteModel) => {
    const isRoutePresentInData = Object.values(
      data?.properties?.[0]?.value?.routes ?? {}
    ).some(
      (route) =>
        route.configuration.type.value === newRouteType &&
        route.configuration.targetTopic.value === newRoute &&
        route.configuration.model.value === newRouteModel
    );

    const isRoutePresentInInitial = Object.values(
      newInitialRoutes?.properties?.[0]?.value?.routes ?? {}
    ).some(
      (route) =>
        route.configuration.type.value === newRouteType &&
        route.configuration.targetTopic.value === newRoute &&
        route.configuration.model.value === newRouteModel
    );
    return isRoutePresentInData || isRoutePresentInInitial;
  };

  const addNewRoutes = () => {
    setIsdelete(false);
    if (!newRouteType || !newRouteModel || !newRoute) {
      return;
    }
    let payload = [
      {
        sectionName: data.sectionName,
        parentTypeId: data.parentTypeId,
        properties: [
          {
            presentationType: "Route",
            value: {
              routes: newInitialRoutes?.properties?.[0]?.value?.routes
                ? newInitialRoutes.properties[0].value.routes
                : {},
            },
            targets: data.properties[0].targets,
          },
        ],
      },
    ];
    setNewInitialRoutes(payload[0]);
    let newKey =
      editingRouteKey != null
        ? editingRouteKey
        : `${newRoute}_${newRouteType}_${newRouteModel}`
            .replace(/ /g, "")
            .replace(/\./g, "");
    if (
      checkIsRoutePresent(newRoute, newRouteType, newRouteModel) ||
      newKey === "Hot_VariableData_abbabilitydevice"
    ) {
      if (editingRouteKey == null) {
        notiMsg = "Route already exists, please add a new route";
        // Dispatch your notification action here
        message.error(notiMsg);
      }

      if (editingRouteKey != null) {
        const updatedRoute = {
          configuration: {
            type: { value: newRouteType },
            targetTopic: { value: newRoute },
            model: { value: newRouteModel },
            nameFilter: { value: "*" },
          },
        };
        if (typeOfVariable === "data") {
          data.properties[0].value.routes[newKey] = updatedRoute;
        } else if (typeOfVariable === "tempRoute") {
          newInitialRoutes.properties[0].value.routes[newKey] = updatedRoute;
        }
        dispatch(setRouterEdgeSettings({ payload: data }));
        setIsChanged(true);
      }

      setSudoState((prev) => prev + 1);

      return;
    } else {
      let addedRoute = {
        configuration: {
          type: { value: newRouteType },
          targetTopic: { value: newRoute },
          model: { value: newRouteModel },
          nameFilter: { value: "*" },
        },
      };

      payload[0].properties[0].value.routes[newKey] = addedRoute;

      setNewInitialRoutes(payload[0]);
      dispatch(setTempRoutes({ payload: payload[0] }));
      setIsChanged(true);
      resetRoutes();
      return;
    }
  };

  const editRoute = (type, routeKey) => {
    setIsdelete(false);
    let route;
    if (type === "data") {
      route = data.properties[0].value.routes[routeKey].configuration;
      setToCheckData(data.properties[0].value);
    } else {
      route =
        newInitialRoutes.properties[0].value.routes[routeKey].configuration;
      setToCheckData(newInitialRoutes.properties[0].value);
    }

    console.log(toCheckData);
    setNewRouteType(route.type.value);
    setNewRoute(route.targetTopic.value);
    setNewRouteModel(route.model.value);

    setEditingRouteKey(routeKey);
    console.log(
      route.targetTopic.value,
      route.type.value,
      route.model.value,
      newRoute,
      newRouteType,
      newRouteModel
    );
    console.log(
      toCheckData?.routes[editingRouteKey]?.configuration?.targetTopic?.value ==
        newRoute &&
        toCheckData?.routes[editingRouteKey]?.configuration?.type?.value ==
          newRouteType &&
        toCheckData?.routes[editingRouteKey]?.configuration?.model?.value ==
          newRouteModel
    );

    setTypeOfVariable(type);
    setShowAddRoutesPannel(true);
  };

  const updateRoute = () => {
    const routeAlreadyExists = checkIsRoutePresent(
      newRoute,
      newRouteType,
      newRouteModel
    );
    const isDefaultRoute =
      newRouteType === "variable data" &&
      newRoute === "Hot" &&
      newRouteModel === "abb.ability.device";

    if (routeAlreadyExists || isDefaultRoute) {
      notiMsg = "Route already exists, please update with new details";
      AddEdgeSettingsNotification(
        `${notiMsg}`,
        `EdgeRelatedUpdate_${CreateGuid()}`,

        {
          edgeId: edgeId,
          isEdge: true,
          modalKey: `EdgeRelatedUpdate_${CreateGuid()}`,
          edgeName: localStorage.getItem("edgeName"),
          appName: "",
          successMessage: `${notiMsg}`,
          time: generateTime(),
          isComplete: true,
          limit: 3,
          currStateValue: 0,
          icon: "failed",
        }
      );
      message.error(notiMsg);
      // resetRoutes();
      return;
    }
    const updatedRoute = {
      configuration: {
        type: { value: newRouteType },
        targetTopic: { value: newRoute },
        model: { value: newRouteModel },
        nameFilter: { value: "*" },
      },
    };

    if (typeOfVariable === "data") {
      data.properties[0].value.routes[editingRouteKey] = updatedRoute;
      dispatch(setRouterEdgeSettings({ payload: data }));
    } else if (typeOfVariable === "tempRoute") {
      newInitialRoutes.properties[0].value.routes[editingRouteKey] =
        updatedRoute;
      dispatch(setTempRoutes({ payload: newInitialRoutes }));
    }
    setIsChanged(true);
    resetRoutes();
  };

  const [isdelete, setIsdelete] = useState(false);

  const deleteRoute = (type, routeKey) => {
    console.log(type);
    let updatedRoutes = {};
    if (type == "data") {
      updatedRoutes = { ...data };
    } else {
      updatedRoutes = { ...newInitialRoutes };
    }
    delete updatedRoutes.properties[0].value.routes[routeKey];

    // setIsChanged(true);
    if (type == "data") {
      dispatch(setRouterEdgeSettings({ payload: updatedRoutes }));
      setIsChanged(true);
    } else {
      dispatch(setTempRoutes({ payload: updatedRoutes }));
    }
    setIsdelete(true);

    resetRoutes();
  };

  let notiMsg;
  let oldNotifications = useSelector((state) => state.setPageNotifications);

  const AddEdgeSettingsNotification = async (notiText, notiId, data) => {
    let previosData = [];
    if (oldNotifications.newData.length > 0) {
      previosData = [...oldNotifications.newData];
    }

    previosData.push({
      notiId: `${notiId}`,
      text: `${notiText}`,
      time: data.time,
      data,
    });

    dispatch(
      setPageNotifications({
        payload: {
          isNew: true,
          data: oldNotifications.data,
          newData: previosData,
          // ...oldNotifications.newData,
        },
      })
    );
  };
  const saveData = () => {
    setIsSaving(true);

    let mainChunk = {
      sectionName: data.sectionName,
      parentTypeId: data.parentTypeId,
      properties: [
        {
          presentationType: "Route",
          name: data.properties[0].name,
          value: {
            routes: {
              ...newInitialRoutes?.properties?.[0]?.value?.routes,
              ...data.properties[0].value.routes,
            },
            defaultRoute: { value: defaultRouteValue },
          },
          valueDomain: data.properties[0].valueDomain,

          targets: data.properties[0].targets,
        },
      ],
    };

    // console.log(mainChunk)

    let p = JSON.stringify([mainChunk]);
    let postEdgeSettingsURL = apiEndPoints.SET_ALL_EDGE_SETTINGS.replace(
      "*edgeId*",
      edgeId
    );

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: postEdgeSettingsURL,

      data: p,
    };

    Axios.request(config)
      .then((response) => {
        setIsSaving(false);
        if (response.data[0].status == "Error") {
          notiMsg = "Applying router settings failed, please try again";
          AddEdgeSettingsNotification(
            `${notiMsg}`,
            `EdgeRelatedUpdate_${CreateGuid()}`,

            {
              edgeId: edgeId,
              isEdge: true,
              modalKey: `EdgeRelatedUpdate_${CreateGuid()}`,
              edgeName: localStorage.getItem("edgeName"),
              appName: "",
              successMessage: `${notiMsg}`,
              time: generateTime(),
              isComplete: true,
              limit: 3,
              currStateValue: 0,
              icon: "failed",
            }
          );
          message.error(notiMsg);
          return;
        } else {
          AddEdgeSettingsNotification(
            `${data.sectionName} applied successfully`,
            `edgeSettingUpdate_${CreateGuid()}`,

            {
              edgeId: edgeId,
              isEdge: true,
              modalKey: `edgeSettingUpdate_${CreateGuid()}`,
              setting_type: data.parentTypeId.split("/")[1],
              settingSectionName: data.sectionName,
              edgeName: localStorage.getItem("edgeName"),
              appName: "",
              successMessage: `${data.sectionName} applied successfully`,
              time: generateTime(),
              isComplete: false,
              limit: 3,
              currStateValue: 0,
              icon: "good",
            }
          );
        }

        let initalRender = document?.getElementsByClassName("thisinitialRoute");
        for (let i = 0; i < initalRender.length; i++) {
          initalRender[i].setAttribute("style", "");
        }
        dispatch(setRouterEdgeSettings({ payload: mainChunk }));
        dispatch(setTempRoutes({ payload: {} }));
        setIsChanged(false);
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
        notiMsg = "Applying router settings failed, please try again";
        AddEdgeSettingsNotification(
          `${notiMsg}`,
          `EdgeRelatedUpdate_${CreateGuid()}`,

          {
            edgeId: edgeId,
            isEdge: true,
            modalKey: `EdgeRelatedUpdate_${CreateGuid()}`,
            edgeName: localStorage.getItem("edgeName"),
            appName: "",
            successMessage: `${notiMsg}`,
            time: generateTime(),
            isComplete: true,
            limit: 3,
            currStateValue: 0,
            icon: "failed",
          }
        );
        message.error(notiMsg);
      });
  };

  const [customModelVisible, setCustomModelVisible] = useState(false);
  const customModelRef = useRef(null);

  const onChange = (value) => {
    if (value === "custom") {
      setCustomModelVisible(true);
    } else {
      setNewRouteModel(value);
    }
  };

  const onSearch = (val) => {
    if (val == "") return;
    let contained = routeModules.filter(
      (route) =>
        route.value.toLocaleLowerCase().includes(val.toLowerCase()) ||
        route.label.toLocaleLowerCase().includes(val.toLowerCase())
    );
    console.log("Contained", contained);
    if (contained.length > 0) {
      setShowAddNewModal(false);
      setSearchedValue("");
    } else {
      setSearchedValue(val);
      setShowAddNewModal(true);
    }
  };

  const onCustomModelConfirm = () => {
    setNewRouteModel(searchedValue);
    const newOption = { label: searchedValue, value: searchedValue };
    setRoutesModules([...routeModules, newOption]);
    setShowAddNewModal(false);
  };

  const trueFalse = () => {
    if (ischanged) {
      dispatch(isUnsavedEdgeSettings({ payload: true }));
      return false;
    } else {
      dispatch(isUnsavedEdgeSettings({ payload: false }));
      return true;
    }
  };

  const settingDefaultRouteValue = (val) => {
    setDefaultRouteValue(val);
    if (val == data.properties[0].value.defaultRoute.value) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
  };

  const renderInputs = (ele) => {
    switch (ele?.presentationType) {
      case "Route":
        let routes = ele?.value?.routes;
        let initial = newInitialRoutes?.properties?.[0]?.value?.routes;
        if (initial == null) initial = [];
        let initialkeys = Object.keys(initial);

        if (routes == null) routes = [];
        let routesKey = Object.keys(routes);

        return (
          <>
            <div
              style={{
                display: "flex",
                columnGap: "10px",
                alignItems: "center",
              }}
            >
              <div style={{ fontWeight: 500 }} className="text_1">
                {ele?.name}
              </div>
              {/* <div style={{ width: "60px" }}> */}

              {ele?.valueDomain && (
                <Select
                  style={{ width: "200px" }}
                  value={defaultRouteValue}
                  onChange={(e) => settingDefaultRouteValue(e)}
                >
                  {ele?.valueDomain
                    ?.filter((e) => !e.includes("Cloud"))
                    .map((e, k) => {
                      let refined_values = e.split("@");

                      return (
                        // eslint-disable-next-line react/jsx-no-undef
                        <Option value={refined_values[1]} key={k}>
                          {refined_values[1]}
                        </Option>
                      );
                    })}
                </Select>
              )}

              {/* </div> */}
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "30px",
                marginBottom: "20px",
              }}
            >
              <span
                style={{ display: "block", fontWeight: 500 }}
                className="heading_2"
              >
                Specific Routes
              </span>
              <span
                onClick={() => {
                  setShowAddRoutesPannel(true);
                  resetRoutes();
                  setaddroutesEnable(true);
                }}
                style={{
                  marginLeft: "auto",
                  background: addroutesEnable
                    ? General_color.disable
                    : "#1f1f1f",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "31px",
                  padding: "2px 10px",
                  // boxShadow: " 0px 4px 6px -1px #d2d2d2",
                  cursor: "pointer",
                  color: "white",
                  fontFamily: "ABBVoice",
                  fontSize: "15px",
                }}
              >
                <span style={{ fontSize: "25px" }} /> Add Routes
                {/* <IoIosAdd style={{ fontSize: "25px" }} /> Add Routes */}
              </span>
            </div>
            <div style={{ display: "flex", columnGap: "10px" }}>
              <div
                style={{
                  width: showAddRoutesPannel ? "66%" : "100%",
                  overflow: "auto",
                  height: "294px",
                }}
              >
                <table className="RoutesTable" id="myTable">
                  <thead
                    style={{
                      background: "white",
                      position: "sticky",
                      top: "0",
                    }}
                  >
                    <tr>
                      <th>Type</th>
                      <th>Route</th>
                      <th>Model</th>
                      <th>Filter</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {routesKey.length > 0 &&
                      routesKey.map((route, key) => (
                        <tr>
                          <td>
                            {ele.value.routes[route]?.configuration.type
                              .value == "timeseries"
                              ? "variable data"
                              : ele.value.routes[route]?.configuration.type
                                  .value}
                          </td>
                          <td>
                            {
                              ele.value.routes[route]?.configuration.targetTopic
                                .value
                            }
                          </td>
                          <td>
                            {ele.value.routes[route]?.configuration.model.value}
                          </td>
                          <td>
                            {
                              ele.value.routes[route]?.configuration.nameFilter
                                .value
                            }
                          </td>
                          <td>
                            <div
                              className="action_buttons"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                rowGap: "10px",
                              }}
                            >
                              {route != "deviceRoute" &&
                                route != "deviceRouteHot" && (
                                  <>
                                    <Tooltip title="Edit Route">
                                      <MdOutlineEdit
                                        style={{ cursor: "pointer" }}
                                        onClick={() => editRoute("data", route)}
                                      />
                                    </Tooltip>

                                    <Tooltip title="Delete Route">
                                      <MdOutlineDelete
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "30%",
                                        }}
                                        onClick={() =>
                                          deleteRoute("data", route)
                                        }
                                      />
                                    </Tooltip>
                                  </>
                                )}
                              {/* <MdOutlineDelete
                              style={{ cursor: "pointer", marginLeft: "30%" }}
                            /> */}
                            </div>
                          </td>
                        </tr>
                      ))}

                    {initialkeys.length > 0 &&
                      initialkeys.map((route, key) => (
                        <tr
                          style={{ background: General_color.disable }}
                          className="thisinitialRoute"
                        >
                          <td>
                            {newInitialRoutes.properties[0].value.routes[route]
                              ?.configuration.type.value == "timeseries"
                              ? "variable data"
                              : newInitialRoutes.properties[0].value.routes[
                                  route
                                ]?.configuration.type.value}
                          </td>
                          <td>
                            {
                              newInitialRoutes.properties[0].value.routes[route]
                                ?.configuration.targetTopic.value
                            }
                          </td>
                          <td>
                            {
                              newInitialRoutes.properties[0].value.routes[route]
                                ?.configuration.model.value
                            }
                          </td>
                          <td>
                            {
                              newInitialRoutes.properties[0].value.routes[route]
                                ?.configuration.nameFilter.value
                            }
                          </td>
                          <td>
                            <div
                              className="action_buttons"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                rowGap: "10px",
                              }}
                            >
                              <Tooltip title="Edit Route">
                                <MdOutlineEdit
                                  style={{ cursor: "pointer" }}
                                  onClick={() => editRoute("tempRoute", route)}
                                />
                              </Tooltip>
                              <Tooltip title="Delete Route">
                                <MdOutlineDelete
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "30%",
                                  }}
                                  onClick={() =>
                                    deleteRoute("tempRoute", route)
                                  }
                                />
                              </Tooltip>
                              {/* <MdOutlineDelete
                              style={{ cursor: "pointer", marginLeft: "30%" }}
                            /> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div
                className="sidePannel"
                style={
                  showAddRoutesPannel
                    ? {
                        width: "30%",
                        borderLeft: "1px solid #d2d2d2",
                        padding: "0px 10px",
                        height: "auto",
                      }
                    : { width: "0%" }
                }
              >
                <span>
                  <label>Type</label>
                  <br />
                  <Select
                    value={newRouteType}
                    onChange={(e) => setNewRouteType(e)}
                    style={{ width: "100%" }}
                    options={typeModules}
                  >
                    <Option value="">select</Option>
                    {/* {routesModel?.properties.types.map((type, key) => (
                      // eslint-disable-next-line react/jsx-no-undef
                      <Option key={key} value={type}>
                        {type}
                      </Option>
                    ))} */}
                  </Select>
                </span>
                <span>
                  <label>Route</label>
                  <br />
                  <Select
                    value={newRoute}
                    onChange={(e) => setNewRoute(e)}
                    style={{ width: "100%" }}
                    options={modelsRoute}
                  >
                    <Option selected value="">
                      select
                    </Option>

                    {/* {routesModel?.properties.routes.map((route, key) => (
                      <Option key={key} value={route}>
                        {route}
                      </Option>
                    ))} */}
                  </Select>
                </span>

                <span>
                  <label>Model</label>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    <Select
                      showSearch
                      placeholder="Select"
                      optionFilterProp="children"
                      onChange={onChange}
                      onSearch={onSearch}
                      value={newRouteModel}
                      style={{ width: "100%" }}
                      dropdownRender={(menu) => <>{menu}</>}
                    >
                      {routeModules?.map((option) => (
                        <Option
                          key={option?.value}
                          value={option?.value}
                          ref={
                            option?.value === newRouteModel
                              ? customModelRef
                              : null
                          }
                        >
                          {option?.label}
                        </Option>
                      ))}
                    </Select>
                    {showAddNewModal && (
                      // <span
                      //   style={{
                      //     flex: "none",
                      //     padding: "8px",
                      //     display: "block",
                      //     cursor: "pointer",
                      //   }}
                      //   onClick={onCustomModelConfirm}
                      // >
                      //   Add
                      // </span>
                      <Button
                        style={{
                          background: "#1f1f1f",
                          alignItems: "center",
                          borderRadius: "31px",
                          padding: "5px 10px",
                          boxShadow: "unset",
                          fontWeight: "500",
                          border: "0px",
                          color: "white",
                          fontFamily: "ABBVoice",
                        }}
                        onClick={onCustomModelConfirm}
                      >
                        Add
                      </Button>
                    )}
                  </div>
                </span>

                <span>
                  <label>Filter</label>
                  <br />
                  {/* <Select style={{ width: "100%" }}>
                    <Option value="*" selected>
                      *
                    </Option>
                  </Select> */}
                  <Input value="*" disabled />
                </span>

                <span style={{ display: "flex" }}>
                  <Button
                    style={{
                      background:
                        editingRouteKey !== null
                          ? toCheckData?.routes[editingRouteKey]?.configuration
                              ?.targetTopic?.value == newRoute &&
                            toCheckData?.routes[editingRouteKey]?.configuration
                              ?.type?.value == newRouteType &&
                            toCheckData?.routes[editingRouteKey]?.configuration
                              ?.model?.value == newRouteModel
                            ? General_color.disable
                            : "#1f1f1f"
                          : newRouteType !== "select" &&
                            newRouteModel !== "select" &&
                            newRoute !== "select"
                          ? "#1f1f1f"
                          : General_color.disable,
                      alignItems: "center",
                      borderRadius: "31px",
                      padding: "5px 10px",
                      // boxShadow: " 0px 4px 6px -1px #d2d2d2",
                      cursor:
                        editingRouteKey !== null
                          ? toCheckData?.routes[editingRouteKey]?.configuration
                              ?.targetTopic?.value == newRoute &&
                            toCheckData?.routes[editingRouteKey]?.configuration
                              ?.type?.value == newRouteType &&
                            toCheckData?.routes[editingRouteKey]?.configuration
                              ?.model?.value == newRouteModel
                            ? "not-allowed"
                            : "pointer"
                          : newRouteType !== "select" &&
                            newRouteModel !== "select" &&
                            newRoute !== "select"
                          ? "pointer"
                          : "not-allowed",
                      fontWeight: "500",
                      color: "white",
                      fontFamily: "ABBVoice",
                    }}
                    // onClick={addNewRoutes}
                    onClick={
                      // editingRouteKey !== null ? updateRoute : addNewRoutes
                      editingRouteKey !== null
                        ? toCheckData?.routes[editingRouteKey]?.configuration
                            ?.targetTopic?.value == newRoute &&
                          toCheckData?.routes[editingRouteKey]?.configuration
                            ?.type?.value == newRouteType &&
                          toCheckData?.routes[editingRouteKey]?.configuration
                            ?.model?.value == newRouteModel
                          ? () => {}
                          : updateRoute
                        : newRouteType !== "select" &&
                          newRouteModel !== "select" &&
                          newRoute !== "select"
                        ? addNewRoutes
                        : () => {}
                    }
                    disable={
                      editingRouteKey !== null
                        ? toCheckData?.routes[editingRouteKey]?.configuration
                            ?.targetTopic?.value == newRoute &&
                          toCheckData?.routes[editingRouteKey]?.configuration
                            ?.type?.value == newRouteType &&
                          toCheckData?.routes[editingRouteKey]?.configuration
                            ?.model?.value == newRouteModel
                          ? true
                          : false
                        : newRouteType !== "select" &&
                          newRouteModel !== "select" &&
                          newRoute !== "select"
                        ? false
                        : true
                    }
                  >
                    {editingRouteKey !== null ? "Update Route" : "Add Route"}
                  </Button>
                  <Button
                    variant="outline"
                    style={{
                      borderRadius: "100px",
                      marginLeft: "10px",
                      fontWeight: "500",
                      padding: "5px 10px",
                      fontFamily: "ABBVoice",
                    }}
                    onClick={resetRoutes}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="outline"
                    style={{
                      borderRadius: "100px",
                      marginLeft: "10px",
                      fontWeight: "500",
                      padding: "5px 10px",
                      fontFamily: "ABBVoice",
                    }}
                    onClick={() => {
                      setShowAddRoutesPannel(false);
                      setaddroutesEnable(false);
                    }}
                  >
                    Done
                  </Button>
                </span>
              </div>
            </div>
          </>
        );

      default:
        break;
    }
  };
  return (
    <>
      {/* <h2 style={{fontFamily:'ABBvoice-medium'}}>{data.sectionName}</h2>
      <hr className="horizontal-line" /> */}

      <div className="routerSettingsContainer">
        {data?.properties?.map((ele, key) => (
          <div key={key}>{renderInputs(ele)}</div>
        ))}

        <div
          className="button-group"
          style={{
            display: "flex",
            // columnGap: "10px",
            alignItems: "baseline",
            marginTop: "20px",
          }}
        >
          {/* <Button
              type="primary"
              style={{ borderRadius: "100px", marginTop: "20px" }}
              
              >
              Apply
            </Button> */}

          <Button
            loading={isSaving}
            // icon={<PoweroffOutlined />}
            style={{
              background: trueFalse() ? General_color.disable : "#1f1f1f",
              alignItems: "center",
              borderRadius: "31px",
              padding: "5px 10px",
              // boxShadow: " 0px 4px 6px -1px #d2d2d2",
              cursor: "pointer",
              fontWeight: "500",
              border: "0px",
              color: "white",
              marginLeft: "auto",
              fontFamily: "ABBVoice",
            }}
            onClick={saveData}
            disabled={trueFalse()}
          >
            Apply
          </Button>

          <Button
            variant="outline"
            style={{
              borderRadius: "100px",
              marginTop: "20px",
              marginLeft: "20px",
              fontWeight: "500",
              padding: "5px 10px",
              fontFamily: "ABBVoice",
              color: trueFalse() ? General_color.disable : "#000",
            }}
            onClick={() => {
              setIsChanged(false);
              setNewInitialRoutes([]);
            }}
            disabled={trueFalse()}
          >
            Reset
          </Button>
        </div>
      </div>
    </>
  );
}

export default Widget;
