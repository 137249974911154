import React, { useEffect, useState } from "react";
import * as PageRoutes from "../../../constants/PageRoutes";
import axios from "axios";

let logOutTimeOut: any;
let activeApiRequests = 0; // Counter for active API requests

interface timeoutHandlerProps {
  onLogout: () => void;
 
}
const TimeOutHandler = (props: timeoutHandlerProps) => {
  const signOutTime = 1800000; //30min
  // const signOutTime = 60000; //1min

  const startTimeOut = () => {
    if (activeApiRequests === 0) { // Only start timeout if no active API requests
      logOutTimeOut = setTimeout(logOut, signOutTime);
    }
  };

  const resetTimeout = () => {
    clearTimeout(logOutTimeOut);
    startTimeOut();
  };



  // const signOutTime = 20000;

  axios.interceptors.request.use(
    (config) => {
      activeApiRequests++; // Increment counter on request start
      resetTimeout();
      // console.log('API request started, active requests:', activeApiRequests);
      return config;
    },
    (error) => {
      activeApiRequests--; // Decrement counter on request error
      // console.log('API request error, active requests:', activeApiRequests);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      activeApiRequests--; // Decrement counter on response success
      // console.log('API request completed, active requests:', activeApiRequests);
      return response;
    },
    (error) => {
      activeApiRequests--; // Decrement counter on response error
      // console.log('API request error, active requests:', activeApiRequests);
      return Promise.reject(error);
    }
  );

  React.useEffect(() => {
    const eventsList = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
      "keydown",
    ];
    eventsList.map((event) => {
      document.addEventListener(event, resetTimeout);
    });
    startTimeOut();
    return () => {
      eventsList.map((event) => {
        document.removeEventListener(event, resetTimeout);
        clearTimeout(logOutTimeOut);
      });
    };
  }, []);

  let keys = Object.keys(PageRoutes.breadcrumbNameMap);
  let saveTheRoutes: { route: string; value: string | null }[] = [];
  // let prev_edgeName: any = "";
  const logOut = () => {
    keys.map((route) => {
      saveTheRoutes.push({
        route,
        value: localStorage.getItem(route),
      });
    });
    // prev_edgeName = localStorage.getItem("edgeName");
    props.onLogout();
    saveTheRoutes.map((route) => {
      if (route.value == null) {
        return;
      }
      localStorage.setItem(route.route, route.value);
    });
    // localStorage.setItem("edgeName", prev_edgeName);
  };
  return <div />;
};

export default TimeOutHandler;
