import axios from "axios";
import jwt_decode from "jwt-decode";
import { commonAuthHelper } from "./App";
import { envSettings } from "./EnviornmentSettings/Settings";
import Store from "./reduxStore/store";


export function register() {
    axios.interceptors.request.use(
        async config => {
            const token = commonAuthHelper.getAccessToken();
            const decodedToken = token && token !== null ? jwt_decode(token) : "";
            const decodedTokenStr = JSON.stringify(decodedToken);
            const authDataToken = JSON.parse(decodedTokenStr);
            if (!authDataToken.roles) {
                throw new axios.Cancel('Abort');
            }
          
            const tenant = localStorage.getItem("tenant");
            config.headers = {
                'Authorization': `Bearer ${token}`,
            }
            if (envSettings.connectivityMode === "connected") {
                
                config.headers = {
                    ...config.headers,
                    'X-Tenant': tenant,
                    'Accept': 'application/json',
                    "Content-Type": "application/json",
                }
            }
            return config;
        },
        error => {
            return Promise.reject(error)
        });

    axios.interceptors.response.use(
        res => res,
        err => {
            Store.dispatch({
                type: "ON_FAIL_SERVERS",
            });
            if (err.message === 'Abort') {
                console.log('Unauthorized');
                Store.dispatch({ type: "SHOW_UNAUTHORIZED_PAGE", errorType: `403` })
            }
            else if (err.response &&
                (err.response.status === 401 || err.response.status === 403)) {
                Store.dispatch({ type: "SHOW_UNAUTHORIZED_PAGE", errorType: `${err.response.status}` })
            }
            else if (err.response &&
                err.response.status === 502) {
                Store.dispatch({
                    type: "SHOW_NOTIFICATION",
                    notificationType: "alarm",
                    message: "Failed to connect server. Please try again, or contact your system administrator if the problem persists.",
                });
            }
            else {
                console.log(err)
                throw err
            }
        }
    )
}