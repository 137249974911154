import React from "react";
import Sidebar from "../components/SideBar/Sidebar";
import AppDetailsComponent from "./pageComponents/AppDetailsComponent";
import { useParams } from "react-router";

function AppDetailPage() {
  const settingData = sessionStorage.getItem("settingData");
  const _settingData = settingData ? JSON.parse(settingData) : null;

  return (
    <section className="row-flex">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="MainContainer">
        {_settingData && <AppDetailsComponent settingData={_settingData} />}
      </div>
    </section>
  );
}

export default AppDetailPage;
