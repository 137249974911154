import React, { useState } from "react";
import { Modal, Button, Tooltip, Form, Input, message } from "antd";
import { RiDeleteBin6Fill } from "react-icons/ri";
import axios from "axios";
import "./deleteEdge.css";
import SpinnerLoader from "../SpinnerLoader/SpinnerLoader";
import { General_color } from "../../constants/GeneralConstants";
import { CreateGuid, generateTime } from "../../constants/commonFunction";
import { useDispatch, useSelector } from "react-redux";
import { setPageNotifications } from "../../reduxStore/actions";
import { apiEndPoints } from "../../constants/ApiEndPoints";

const DeleteEdge = ({ edgeId, edgeName }) => {
  const dispatch = useDispatch();
  const [deletemodalLoad, setdeleteModalLoad] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false);
  const [load, setLoad] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [deleteAppForm] = Form.useForm();

  const showModal = () => setDeleteModalVisible(true);
  const handleDeleteCancel = () => setDeleteModalVisible(false);
  let oldNotifications = useSelector((state) => state.setPageNotifications);
  const AddEdgeSettingsNotification = async (notiText, notiId, data) => {
    let previosData = [];
    if (oldNotifications.newData.length > 0) {
      previosData = [...oldNotifications.newData];
    }

    previosData.push({
      notiId: `${notiId}`,
      text: `${notiText}`,
      time: data.time,
      data,
    });

    dispatch(
      setPageNotifications({
        payload: {
          isNew: true,
          data: oldNotifications.data,
          newData: previosData,
          // ...oldNotifications.newData,
        },
      })
    );
  };

  const handleDeleteEdge = async () => {
    if (confirmationText !== edgeName) {
      message.error("Please enter the edge name correctly to confirm.");
      return;
    }
    setLoad(true);

    let deleteEdgeURL = apiEndPoints.DELETE_EDGE.replace("*edgeId*", edgeId);
    const config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: deleteEdgeURL,
    };

    try {
      const response = await axios.request(config);
      if (response.status === 200) {
        let notiMsg = `Edge ${edgeName} deleted successfully. Redirecting to Inventory Page.`;
        AddEdgeSettingsNotification(
          `${notiMsg}`,
          `EdgeRelatedUpdate_${CreateGuid()}`,
          {
            edgeId: edgeId,
            isEdge: true,
            modalKey: `EdgeRelatedUpdate_${CreateGuid()}`,
            edgeName: localStorage.getItem("edgeName"),
            appName: "",
            successMessage: `${notiMsg}`,
            time: generateTime(),
            isComplete: true,
            limit: 3,
            currStateValue: 0,
            icon: "good",
          }
        );
        setDeleteModalVisible(false);
        setConfirmationText("");
        setIsDeleteButtonEnabled(false);
        setTimeout(() => {
          window.location.href = "/inventory";
        }, 3000);
      }
    } catch (error) {
      console.error("Error deleting edge:", error);
      let notiMsg = `Failed to delete edge ${edgeName}. Please try again.`;
      AddEdgeSettingsNotification(
        `${notiMsg}`,
        `EdgeRelatedUpdate_${CreateGuid()}`,
        {
          edgeId: edgeId,
          isEdge: true,
          modalKey: `EdgeRelatedUpdate_${CreateGuid()}`,
          edgeName: localStorage.getItem("edgeName"),
          appName: "",
          successMessage: `${notiMsg}`,
          time: generateTime(),
          isComplete: true,
          limit: 3,
          currStateValue: 0,
          icon: "failed",
        }
      );
      setDeleteModalVisible(false);
    } finally {
      setLoad(false);
    }
  };

  return (
    <div>
      <Tooltip title="Delete edge">
        <RiDeleteBin6Fill
          onClick={showModal}
          style={{
            fontSize: "25px",
            cursor: "pointer",
          }}
          className="icon"
        />
      </Tooltip>
      <Modal
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            Warning: Delete - {edgeName || ""}
            {deletemodalLoad && (
              <SpinnerLoader style={{ marginLeft: "10px" }} />
            )}
          </div>
        }
        open={deleteModalVisible}
        onCancel={handleDeleteCancel}
        footer={null}
      >
        <p style={{ fontSize: "medium", fontFamily: "ABBVoice" }}>
          Are you certain you want to remove the {edgeName} edge? This action
          will <b>permanently</b> delete the edge and cannot be undone.
          <p>To confirm deletion, please type the name of the edge:</p>
        </p>
        <Form
          form={deleteAppForm}
          onFinish={handleDeleteEdge}
          layout="vertical"
        >
          <Form.Item
            name="confirmation"
            rules={[
              {
                validator: (_, value) => {
                  if (value === edgeName) {
                    setIsDeleteButtonEnabled(true);
                    return Promise.resolve();
                  }
                  setIsDeleteButtonEnabled(false);
                  return Promise.reject(
                    "Please enter the edge name correctly to confirm."
                  );
                },
              },
            ]}
          >
            <Input
              autoComplete="off"
              onChange={(e) => setConfirmationText(e.target.value)}
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <Button
              style={{
                background: isDeleteButtonEnabled ? "#1f1f1f" : "#848484",
                color: "white",
                borderRadius: "30px",
              }}
              htmlType="submit"
              disabled={!isDeleteButtonEnabled || load}
              loading={load}
            >
              {load ? "Deleting edge..." : "Confirm"}
            </Button>
            <Button
              style={{
                borderRadius: "30px",
                fontWeight: "500",
                color: load && General_color.disable,
              }}
              onClick={handleDeleteCancel}
              disabled={load}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default DeleteEdge;
